import React from 'react';
import AccordionItem from './AccordionItem'; // Adjust the path as needed

const Accordion = ({ items, isVisible, handleToggleAccordion, tabs, activeTab, handleTabChange }) => (
    <div className="accordion">
        {items.map((item, index) => (
            <AccordionItem
                key={`accor_${index}`}
                item={item}
                isVisible={isVisible}
                handleToggleAccordion={handleToggleAccordion}
                tabs={tabs}
                activeTab={activeTab}
                handleTabChange={handleTabChange}
            />
        ))}
    </div>
);

export default Accordion;
