// src/components/Modal.js

import React from 'react';

function Modal({ isOpen, onClose, children }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/2 relative">
                <button
                    onClick={onClose}
                    className="text-gray-700 absolute top-3 right-3 text-xl"
                >
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
}

export default Modal;
