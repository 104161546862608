import React from 'react';
import LoadingSpinner from '../utils/LoadingSpinner';
import InputFieldLight from '../utils/InputFieldLight';
import ActionButton from '../utils/ActionButton';
import Modal from './Modal'; // Import the base Modal component
import { useTranslation } from 'react-i18next';

const PasswordChangeModal = ({
    isOpen,
    onClose,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    errorMessage,
    handlePasswordChange,
    loading,
}) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="space-y-4">
                <h2 className="text-lg md:text-xl font-semibold mb-4">{t('profile.change_password')}</h2>
                {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

                <InputFieldLight
                    type="password"
                    placeholder={t('profile.old_password')}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    className="mb-4"
                    required
                />
                <InputFieldLight
                    type="password"
                    placeholder={t('profile.new_password')}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="mb-4"
                    required
                />

                <InputFieldLight
                    type="password"
                    placeholder={t('profile.confirm_password')}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mb-4"
                    required
                />

                <div className="flex justify-end space-x-4">
                    <ActionButton
                        onClick={handlePasswordChange}
                        label={loading ? <LoadingSpinner /> : t('profile.change_password')}
                        disabled={loading}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default PasswordChangeModal;
