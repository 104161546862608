import React from 'react';

const Metric = ({ value, label }) => {
  return (
    <div className="metric bg-secondary p-4 mb-2 rounded">
      <div className="value text-2xl text-primaryAccent mb-1">{value}</div>
      <div className="label text-sm text-graySoft">{label}</div>
    </div>
  );
};

export default Metric;
