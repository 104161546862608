import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

const History = () => {
    const { videoId } = useParams(); // Get video ID from URL
    const navigate = useNavigate();
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                const response = await axiosInstance.get(`/api/sessions/video/${videoId}/`);
                setSessions(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message || "Error fetching session history");
                setLoading(false);
            }
        };

        fetchSessions();
    }, [videoId]);

    return (
        <div>
            <Header />
            <hr />
            <main className="pt-14">
                <div className="h-full px-4 py-6 flex justify-center items-center">
                    <div className="w-2/3">
                        {loading ? (
                            <div className="text-gray-500 text-xl mb-4 text-center">Loading...</div>
                        ) : error ? (
                            <div className="text-red-500 text-center">Error: {error}</div>
                        ) : sessions.length === 0 ? (
                            <p className="text-gray-500 text-xl mb-4 text-center">No sessions available for this video.</p>
                        ) : (
                            sessions.map((session) => (
                                <div
                                    key={session.id}
                                    className="bg-secondary text-white shadow-lg rounded-lg mb-8 p-6 transition transform hover:scale-105 hover:shadow-2xl"
                                    onClick={() => navigate(`/session-analysis/${session.id}`)}
                                    style={{
                                        cursor: 'pointer',
                                        borderLeft: '6px solid #3E8E41',
                                    }}
                                >
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="text-xl font-bold">
                                            {new Date(session.session_date).toLocaleString()}
                                        </h3>
                                        <span className="bg-primaryAccent text-xs font-bold py-1 px-3 rounded">
                                            {session.snapshots_count} Snapshots
                                        </span>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4 mt-4 text-sm text-gray-300">
                                        <div className="bg-primary p-3 rounded-md shadow-md">
                                            <p className="font-semibold">Total Steps</p>
                                            <p className="text-lg font-bold">
                                                {session.summary.total_steps || 'N/A'}
                                            </p>
                                        </div>
                                        <div className="bg-primary p-3 rounded-md shadow-md">
                                            <p className="font-semibold">Average Step Length</p>
                                            <p className="text-lg font-bold">
                                                {session.summary.average_step_length || 'N/A'}
                                            </p>
                                        </div>
                                        <div className="bg-primary p-3 rounded-md shadow-md">
                                            <p className="font-semibold">Max Cadence</p>
                                            <p className="text-lg font-bold">
                                                {session.summary.max_cadence || 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </main>
            <Footer />
        </div>

    );
};

export default History;
