import React from 'react';
import { useTranslation } from 'react-i18next';
import Metric from './Metric';
import SidebarHeader from './SidebarHeader';
const Metrics = ({
	isVisible,
	toggleLeftSidebar,
	totalSessionTime,
	stepCount,
	stepLength,
	isSmallScreen,
	firstContact,
	headAngle,
	trunkAngle,
	elbowAngle,
	verticalOscillation,
	flightTime,
	frontFootAngle,
	backFootAngle,
	FootContactTime,
}) => {
	const { t } = useTranslation();

	return (
		<div
			className={`${
				isVisible
				? isSmallScreen
					? 'absolute w-64 z-40 h-full'
					: 'w-2/12'
				: isSmallScreen
				? 'absolute w-16 z-40 h-full'
				: 'w-0.5/12'
			} transition-all duration-300`}
			>
			<div className="menu_item menu_left align-self-start col-2 order-1 bg-primary h-full">
				<SidebarHeader
					isVisible={isVisible}
					title={t('metrics.all_metrics')}
					onToggleSidebar={toggleLeftSidebar}
					arrowDirection="left"
					iconAlt={t('metrics.person_running_icon_alt')}
					reverseOrder={true}
				/>
				{isVisible && (
				<div className="metrics w-full">
					<Metric value={firstContact} label={t('metrics.ground_contact_time')} />
					<Metric value={headAngle} label={t('metrics.head_position')} />
					<Metric value={trunkAngle} label={t('metrics.trunk_position')} />
					<Metric value={verticalOscillation} label={t('metrics.vertical_oscillation')} />
					<Metric value={frontFootAngle} label={t('metrics.front_foot_angle')} />
					<Metric value={backFootAngle} label={t('metrics.back_foot_angle')} />
					<Metric value={elbowAngle} label={t('metrics.elbow_angle')} />
					<Metric value={flightTime} label={t('metrics.flight_time')} />
					<Metric value={FootContactTime} label={t('metrics.ground_contact_time')} />
					<Metric
						value={`${Math.floor(totalSessionTime / 60)}:${('0' + (totalSessionTime % 60)).slice(-2)}`}
						label={t('metrics.total_session_time')}
					/>
					<Metric value={stepCount} label={t('metrics.steps')} />
					<Metric value={`${stepLength} m`} label={t('metrics.step_length')} />
				</div>
				)}
		</div>
		</div>
	);
};

export default Metrics;
