import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Metrics from '../../components/video-analysis/Metrics';
import Analysis from '../../components/video-analysis/Analysis';
import VideoContent from '../../components/video-analysis/VideoContent';
import StepsChart from '../../components/video-analysis/StepsChart';
import { BASE_URL } from '../../config';
import { Pose } from '@mediapipe/pose';
import { drawLandmarks, drawConnectors } from '@mediapipe/drawing_utils';
import { useTranslation } from 'react-i18next';
import { getAngle, calcVectorAngle } from '../../utils/math';
import { fetchVideoDetails, fetchVideoBookmarks, createSession, sendMetrics } from '../../services/videoService';
import MainLayout from '../../components/layouts/MainLayout';

function VideoAnalysis() {
    const { videoId } = useParams();
    const { t, i18n } = useTranslation();

    const [activeTab, setActiveTab] = useState(0);
    // const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    // Translate accordion items
    const getTranslatedAccordionItems = () => [
        { id: 1, isOpen: true, percentage: 100, name: t('video_analysis.head_position'), status: t('video_analysis.great') },
        { id: 2, isOpen: false, percentage: 100, name: t('video_analysis.back_position'), status: t('video_analysis.great') },
        { id: 3, isOpen: false, percentage: 70, name: t('video_analysis.foot_landing'), status: t('video_analysis.good') },
        { id: 4, isOpen: false, percentage: 70, name: t('video_analysis.foot_contact'), status: t('video_analysis.good') },
        { id: 5, isOpen: false, percentage: 10, name: t('video_analysis.contact_time'), status: t('video_analysis.good') },
        { id: 6, isOpen: false, percentage: 10, name: t('video_analysis.knee_flexion'), status: t('video_analysis.good') }
    ];

    const [accordionItems, setAccordionItems] = useState(getTranslatedAccordionItems);

    // Update accordion items when language changes
    useEffect(() => {
        setAccordionItems(getTranslatedAccordionItems());
    }, [i18n.language]);

    const updateAccordionItem = (id, newPercentage, newStatus) => {
        setAccordionItems(prevItems =>
            prevItems.map(item =>
                item.id === id
                    ? { ...item, percentage: newPercentage, status: t(`video_analysis.${newStatus}`) }
                    : item
            )
        );
    };

    const [videoSrc, setVideoSrc] = useState(null);
    const [totalSessionTime, setTotalSessionTime] = useState(0);
    // const [stepCount, setStepCount] = useState(0);
    // const [stepLength, setStrideLength] = useState(0);
    const [isLeftSidebarVisible, setIsLeftSidebarVisible] = useState(true);
    const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1300); // Track small screen size

    const [poseLandmarker, setPoseLandmarker] = useState(null);
    //new variables

    //values for dsiaplying which foot part strikes the ground first
    // const [firstContact, setFirstContact] = useState("");
    // To track foot ground contact time
    // const [FootContactTime, setFootContactTime] = useState(null);

    // for dsiaplaying the angles of the foot at time of striking the grounf
    // const [frontFootAngle, setFrontFootAngle] = useState(null);
    // const [backFootAngle, setBackFootAngle] = useState(null);

    //for dispalying the head which is the angle formed by the vertical vector and eyes and shoulder
    // const [headAngle, setHeadAngle] = useState(null);

    //for dispalying the Trunk Angle which is the angle formed by the vertical vector and hips and shoulders
    // const [trunkAngle, setTrunkAngle] = useState(null);

    //for dispalying the Fligth time
    // const [fligthTime,setFligthTIme] = useState(null);

    //for displaying vertical oscilation
    // const [verticalOscillation,setVOscillation] = useState(null);

    //const for arms angle
    const [shoulderAngle,setShoulderAngle] = useState(null);
    // const [elbowAngle,setElbowAngle] = useState(null);
    // for displaying analysis stats
    // const [cadence,setCadence] = useState(null);

    const stepCountRef = useRef(0);
    const stepLengthRef = useRef(0);
    const cadenceRef = useRef(null);
    const elbowAngleRef = useRef(null);
    const verticalOscillationRef = useRef(null);
    const flightTimeRef = useRef(null);
    const trunkAngleRef = useRef(null);
    const headAngleRef = useRef(null);
    const backFootAngleRef = useRef(null);
    const frontFootAngleRef = useRef(null);
    const footContactTimeRef = useRef(null);
    const firstContactRef = useRef(null);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const vertical_vector = [0,1];
    const knownHeightMeters = 1.75;
    let maxHeight = 0;
    let isSteppingLeft = true;
    let isSteppingRight = false;


    let minZValueL = Infinity;
    let minZValueR = Infinity;
    let midLine = 0;
    let FootContactTimeStart = null;
    let rightFootContactTimeStart = null;
    let scalingFactor = 0;
    let ankleAtContact = null;
    let rightAnkleAtContact = null;
    let fligth_time_begin = null;
    let maxOSC = 0;
    let gctValues = [];
    let strideValues = [];
    let startTimer = null;

    const [sessionID, setSessionID] = useState(null);

	const [bookmarks, setBookmarks] = useState([]);

    const intervalRef = useRef(null);
    const sessionIDRef = useRef(null);

    // 1. Fetch the video blob URL
    // useEffect(() => {
    //     const fetchVideoDetails = async () => {
    //         try {
    //             const response = await axiosInstance.get(`${BASE_URL}/api/videos/video/${videoId}/`);
    //             const videoData = response.data;

    //             // Directly use the main endpoint URL as the video source
    //             setVideoSrc(`${BASE_URL}/${videoData.file}`);
    //         } catch (error) {
    //             console.error('Error fetching video data:', error);
    //         }
    //     };
    //     fetchVideoDetails();
    // }, [videoId]);
    // Load video data
    // useEffect(() => {
    //     const fetchVideoDetails = async () => {
    //         try {
    //             const response = await axiosInstance.get(`${BASE_URL}/api/videos/video/${videoId}/`,
    //                 {
    //                     responseType: 'blob'  // Important for media file fetching
    //                 }
    //             );
    //             // Convert blob to object URL
    //             const videoBlobUrl = URL.createObjectURL(response.data);
    //             setVideoSrc(videoBlobUrl);
    //             // setVideoSrc(`${BASE_URL}/${response.data.file}`);
    //         } catch (error) {
    //             console.error(t('video_analysis.loading_error'), error);
    //         }
    //     };
    //     fetchVideoDetails();
    // }, [videoId, t]);

    useEffect(() => {
        const fetchVideoDetailsAndBookmarks = async () => {
            try {
                // Fetch the video and bookmarks in parallel
                const [videoData, bookmarksData] = await Promise.all([
                    fetchVideoDetails(videoId),
                    fetchVideoBookmarks(videoId),
                ]);
                // Convert the video blob to an object URL
                // const videoBlobUrl = URL.createObjectURL(videoResponse.data);
                // setVideoSrc(videoBlobUrl);
                setVideoSrc(`${BASE_URL}/${videoData.file}`);
                setBookmarks(bookmarksData);
            } catch (error) {
                console.error(t('video_analysis.loading_error'), error);
            }
        };

        fetchVideoDetailsAndBookmarks();
    }, [videoId, t]);

    // Create a session when the video starts
    const handleCreateSession = async () => {
        try {
            const session = await createSession(videoId);
            sessionIDRef.current = session.id;
            setSessionID(session.id);
            console.log("Session created with ID:", session.id);
        } catch (error) {
            console.error("Error creating session:", error);
        }
    };

    // Function to send metric data to backend every 5 seconds
    const handleSendMetrics = async () => {
        if (!sessionIDRef.current) {  // Use ref instead of state
            console.warn("Session ID is not set. Metrics will not be sent.");
            return;
        }

        try {
            const data = {
                session: sessionIDRef.current,
                timestamp: Math.floor(videoRef.current.currentTime), // timestamp in seconds
                metrics: {
                    headAngle: headAngleRef.current,
                    trunkAngle: trunkAngleRef.current,
                    elbowAngle: elbowAngleRef.current,
                    verticalOscillation: verticalOscillationRef.current,
                    flightTime: flightTimeRef.current,
                    frontFootAngle: frontFootAngleRef.current,
                    backFootAngle: backFootAngleRef.current,
                    footContactTime: footContactTimeRef.current,
                    stepCount: stepCountRef.current,
                    stepLength: stepLengthRef.current,
                    cadence: cadenceRef.current,
                    firstContact: firstContactRef.current,                }
            };
            await sendMetrics(sessionIDRef.current, data);
        } catch (error) {
            console.error("Error saving snapshot:", error);
        }
    };


    // Use a ref to track if the interval has already been started
    const startInterval = () => {
        if (!intervalRef.current) {
            console.log("Starting interval");
            intervalRef.current = setInterval(() => {
                handleSendMetrics();
            }, 5000); // Send metrics every 5 seconds
        }
    };

    const stopInterval = () => {
        if (intervalRef.current) {
            console.log("Stopping interval");
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };
    // Use sessionIDRef in handleVideoPlay and handleVideoPauseOrEnd without modifying it
    const handleVideoPlay = async () => {
        console.log("Session ID on play:", sessionIDRef.current);
        startInterval();
        if (!sessionIDRef.current) {
            await handleCreateSession();
        }
    };


    // Clear interval when the video is paused or component unmounts
    const handleVideoPauseOrEnd = () => {
        stopInterval();
    };

    // Attach event listeners to the video unconditionally
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.addEventListener('play', handleVideoPlay);
            video.addEventListener('pause', handleVideoPauseOrEnd);
            video.addEventListener('ended', handleVideoPauseOrEnd);
        }
        // Clean up event listeners on unmount
        return () => {
            if (video) {
                video.removeEventListener('play', handleVideoPlay);
                video.removeEventListener('pause', handleVideoPauseOrEnd);
                video.removeEventListener('ended', handleVideoPauseOrEnd);
            }
        };
    }, [videoSrc]);  // Only run once on mount

    // 2. Initialize Mediapipe Pose model
    useEffect(() => {
        const initializePose = async () => {
            console.log("starting");
            console.log("Loading MediaPipe Pose...");
            const pose = new Pose({
                locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`,
            });


            pose.setOptions({
                modelComplexity: 1,
                smoothLandmarks: true,
                minDetectionConfidence: 0.5,
                minTrackingConfidence: 0.5,
            });
            console.log("Pose object created");  // <-- Check if this runs

            console.log(videoRef.current);
            if (videoRef.current) {
                videoRef.current.playbackRate = 0.25; // Set the playback speed to 0.5x for better processing
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.playbackRate = 0.25; // Ensure it's consistently slow after reload
                };
            }
            pose.onResults(handleResults);

            setPoseLandmarker(pose);

        };

        initializePose();
    }, [videoSrc]);



    // 3. Start analysis when the video is played
    useEffect(() => {
        if (poseLandmarker && videoRef.current) {
            const video = videoRef.current;

            const processVideo = async () => {
                if (video.readyState >= 2 && !video.paused && !video.ended) {
                    await poseLandmarker.send({ image: video });
                    requestAnimationFrame(processVideo); // Continuously process frames
                }
            };

            // Attach processVideo to play event
            const handlePlay = () => {
                processVideo();
            };

            console.log("running video");
            video.addEventListener('play', handlePlay);

            // Cleanup listener on unmount
            return () => {
                video.removeEventListener('play', handlePlay);
            };
        }
    }, [poseLandmarker]);



    const handleResults = (results) => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        const context = canvas.getContext('2d');

        if (!context || !results.poseLandmarks) return;

        // Sync canvas size with video size
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.clearRect(0, 0, canvas.width, canvas.height);

        drawLandmarks(context, results.poseLandmarks, {
            radius: 4,
            color: 'rgb(255, 255, 255)',
        });

        drawConnectors(context, results.poseLandmarks, Pose.POSE_CONNECTIONS, {
            color: 'rgb(0, 255, 0)',
            lineWidth: 4,
        });
        const nose =  results.poseLandmarks[0];
        const leftEye = results.poseLandmarks[2];
        const rightEye = results.poseLandmarks[5];
        const leftShoulder = results.poseLandmarks[11];
        const rightShoulder = results.poseLandmarks[12];
        const leftElbow = results.poseLandmarks[13];
        const leftWrist = results.poseLandmarks[15];
        const leftHip = results.poseLandmarks[23];
        const rightHip = results.poseLandmarks[24];
        const leftKnee = results.poseLandmarks[25];
        const rightKnee = results.poseLandmarks[26];
        const leftAnkle = results.poseLandmarks[27];
        const rightAnkle = results.poseLandmarks[28];
        const leftHeel = results.poseLandmarks[29];
        const rightHeel = results.poseLandmarks[30];
        const leftFootIndex = results.poseLandmarks[31];
        const rightFootIndex = results.poseLandmarks[32];


        //vectors for head angle calculation
        const midEyes = [(leftEye.x + rightEye.x) / 2, (leftEye.y + rightEye.y) / 2];
        const midShoulder = [(leftShoulder.x + rightShoulder.x) / 2, (leftShoulder.y + rightShoulder.y) / 2];
        const head_vector = [midEyes[0] - midShoulder[0], midEyes[1] - midShoulder[1]];

        const hAngle = 170 - calcVectorAngle(head_vector,vertical_vector);
        // setHeadAngle(hAngle.toFixed(2));
        headAngleRef.current = hAngle.toFixed(2);

        if (hAngle >= 0 && hAngle <= 15) {
            updateAccordionItem(1, 90, 'perfect_alignment');
        } else if (hAngle > 15 && hAngle <= 25) {
            updateAccordionItem(1, 70, 'slight_tilt');
        } else if (hAngle > 25 && hAngle <= 35) {
            updateAccordionItem(1, 50, 'noticeable_tilt');
        } else {
            updateAccordionItem(1, 30, 'significant_tilt');
        }





        //vector for trunk angle or torso position angle
        const midHip = [(leftHip.x + rightHip.x) / 2, (leftHip.y + rightHip.y) / 2];
        const trunk_vector = [midShoulder[0] - midHip[0],midShoulder[1] - midHip[1]];

        const tAngle = 180 - calcVectorAngle(trunk_vector,vertical_vector);
        // setTrunkAngle(tAngle.toFixed(2));
        trunkAngleRef.current = tAngle.toFixed(2);

        if(trunkAngleRef  < 5 && trunkAngleRef > 20){
            updateAccordionItem(2,40,"bad");
        }else{
            updateAccordionItem(2,80,"good");
        }

        const elbwAngle = getAngle(leftShoulder,leftElbow,leftWrist);
        // setElbowAngle(elbwAngle.toFixed(2));

        elbowAngleRef.current = elbwAngle.toFixed(2)

        const shldrAngle = getAngle(leftHip,leftShoulder,leftElbow);
        setShoulderAngle(shldrAngle.toFixed(2));

        // getting max mp heigth for calculating pixelsPerMeter
        const currentHeight = Math.abs(nose.y - Math.min(rightAnkle.y, leftAnkle.y));

        // settting heigth to max as it changes while moving
        if (currentHeight > maxHeight) {
            maxHeight = currentHeight;
            // console.log(`New max height: ${maxHeight}`);
        }
        scalingFactor = knownHeightMeters/maxHeight;

        const angleKneeL = getAngle(results.poseLandmarks[27], results.poseLandmarks[25], results.poseLandmarks[23]); // Left leg
        const angleKneeR = getAngle(results.poseLandmarks[28], results.poseLandmarks[26], results.poseLandmarks[24]); // Right leg



        //analysis conditions for the left leg
        if (angleKneeL > 160 && angleKneeR < 120 && !isSteppingLeft && isSteppingRight) {
            isSteppingLeft = true;
            isSteppingRight = false;
            // console.log("entered here");
            const stepLength = Math.abs(leftFootIndex.x - rightFootIndex.x) * scalingFactor*2;
            if (stepLength > 1 && stepLength <3 && strideValues.length < 10 ){
                strideValues.push(stepLength);
                const avgStepLength = strideValues.reduce((a, b) => a + b, 0) / strideValues.length; // Calculate the average
                // setStrideLength(Math.round(Number(avgStepLength) * 100) / 100); // Set stride length to the average, rounded to two decimal places
                // console.log("step left: ",stepLength);
                stepLengthRef.current = Math.round(Number(avgStepLength) * 100) / 100;

            }

            // setStepCount((prev) => prev + 1);
            stepCountRef.current += 1;

            startTimer  = performance.now();



            //this resets z value for other step as terrian migth change
            // setMinZValueR(Infinity)
            minZValueR = Infinity;
            const currentZValueL = leftHeel.z;
            if (currentZValueL < minZValueL) {
                ankleAtContact = leftAnkle;
                minZValueL = currentZValueL;
                // setFootContactTime(Date.now()); // staarts timer
                FootContactTimeStart = performance.now();
                //angles at contact for front foot and back foot
                const angleF = getAngle(leftAnkle, leftKnee,leftHip);
                // console.log("front foot angle:", angleF);
                // setFrontFootAngle(angleF.toFixed(2));

                frontFootAngleRef.current = angleF.toFixed(2);

                if(angleF > 175){
                    updateAccordionItem(3,40,"too_straight");
                }else if(angleF <= 175 && angleF > 150 ){
                    updateAccordionItem(3,90,"good");
                }
                else{
                    updateAccordionItem(3,40,"bent_knee")
                }

                const angleB = getAngle(rightAnkle,rightKnee,rightHip);
                // setBackFootAngle(angleB.toFixed(2));
                backFootAngleRef.current = angleB.toFixed(2);


                if(angleB > 60 && angleB < 100){
                    if(angleB > 70 && angleB < 90){
                    updateAccordionItem(3,70,"good");
                    }
                    else{
                        updateAccordionItem(3,70,"sub_optimal");
                    }
                }else if(angleB < 60 && angleB > 100 ){
                    updateAccordionItem(3,90,"bad");
                }

                midLine = leftHip.y;

          }
          detectFootStrike(leftHeel, leftFootIndex, "left");

        }
        else if (angleKneeR > 160 && angleKneeL < 120 && !isSteppingRight && isSteppingLeft) {
            isSteppingLeft = false;
            isSteppingRight = true;
            // Right-side stepping logic
            const stepLength = Math.abs(rightFootIndex.x - leftFootIndex.x) * scalingFactor * 2;
            // console.log("Length Right:",stepLength);

            if (stepLength > 1 && stepLength <3 && strideValues.length < 10 ){
                strideValues.push(stepLength);
                const avgStepLength = strideValues.reduce((a, b) => a + b, 0) / strideValues.length; // Calculate the average
                // setStrideLength(Math.round(Number(avgStepLength) * 100) / 100); // Set stride length to the average, rounded to two decimal places
                // console.log("step left: ",stepLength);
                stepLengthRef.current = Math.round(Number(avgStepLength) * 100) / 100;
            }

            // setStepCount((prev) => prev + 1);
            stepCountRef.current += 1;

            const cadence = (performance.now() - startTimer)/4000;
            // setCadence(cadence.toFixed(2));

            cadenceRef.current = cadence.toFixed(2)

            // Reset left foot's z value as terrain might change
            // setMinZValueL(Infinity);
            minZValueL = Infinity;

            const currentZValueR = rightHeel.z;
            if (currentZValueR < minZValueR) {
                minZValueR = currentZValueR;
                const angleF = getAngle(rightAnkle, rightKnee, rightHip);

                // setFrontFootAngle(angleF.toFixed(2));
                frontFootAngleRef.current = angleF.toFixed(2);

                if(angleF > 175){
                    updateAccordionItem(3,40,"too_straight");
                }else if(angleF <= 175 && angleF > 150 ){
                    updateAccordionItem(3,90,"good");
                }
                else{
                    updateAccordionItem(3,40,"bent_knee")
                }
                const angleB = getAngle(leftAnkle, leftKnee, leftHip);
                // setBackFootAngle(angleB.toFixed(2));

                backFootAngleRef.current = angleB.toFixed(2)
                if(angleB > 60 && angleB < 100){
                    if(angleB > 70 && angleB < 90){
                    updateAccordionItem(3,70,"good");
                    }
                    else{
                        updateAccordionItem(3,70,"sub_optimal");
                    }
                }else if(angleB < 60 && angleB > 100 ){
                    updateAccordionItem(3,90,"bad");
                }


                midLine = rightHip.y;
            }
            detectFootStrike(rightHeel, rightFootIndex, "right");
        }

        const Vosc = Math.abs((leftHip.y - midLine)*  scalingFactor*50);
        // setVOscillation(Vosc.toFixed(2));

        verticalOscillationRef.current = Vosc.toFixed(2);

        if (Vosc > maxOSC){
            maxOSC = Vosc;
        }

        //fligth time calculations
        if (leftFootIndex.z < -0.1 && isSteppingLeft) {
            fligth_time_begin = performance.now();

        }
        if(isSteppingRight && rightKnee.x < leftKnee.x){
            const angleFlexion = getAngle(results.poseLandmarks[27], results.poseLandmarks[25], results.poseLandmarks[23]); // Left leg
            if(angleFlexion < 60){
                updateAccordionItem(6,40,"bad");
            }else if(angleFlexion >= 60 && angleFlexion < 100){
                updateAccordionItem(6,100,"good")
            }

        }

        if ( isSteppingRight && fligth_time_begin !== null) {
            const fligth_time = (performance.now() - fligth_time_begin) / 4000;
            console.log("FLight time:", fligth_time);
            if (fligth_time > 0.05 && flightTimeRef.current < 0.25){
                // Flight time finished
                // setFlightTime(fligth_time.toFixed(2));
                flightTimeRef.current = fligth_time.toFixed(2);
                fligth_time_begin = null;
            }

        }

        // Ground contact time calculation
        if (FootContactTimeStart !== null && ankleAtContact !== null) {
            if (Math.abs(leftFootIndex.y) < Math.abs(ankleAtContact.y)) {
                const gct = (performance.now() - FootContactTimeStart) / 4000;

                if(gct > 0.1 && gct < 0.4){
                    gctValues.push(gct); // Add new GCT to the array
                    const avgGct = gctValues.reduce((a, b) => a + b, 0) / gctValues.length; // Calculate the average
                    // setFootContactTime(avgGct.toFixed(2));
                    footContactTimeRef.current = avgGct.toFixed(2);

                    if(avgGct < 300){
                        if(avgGct < 200){
                            updateAccordionItem(5,100, "good");
                        }else{
                        updateAccordionItem(5,70,"medium")
                    }
                }
                else{
                    updateAccordionItem(5,10,"bad")
                }
                }
                // Reset contact times and ankle data
                FootContactTimeStart = null;
                ankleAtContact = null;
            }
        }

    };

    const detectFootStrike = (heel, footIndex, side) => {
        const tValue = 0.4;
        const midfootZ = footIndex.z + tValue * (heel.z - footIndex.z);

        if (side === "left") {
            if (Math.abs(heel.z) < Math.abs(footIndex.z) && Math.abs(heel.z) < Math.abs(midfootZ)) {
                // setFirstContact(t('video_analysis.left_heel_strike'));
                firstContactRef.current = t('video_analysis.left_heel_strike');
                updateAccordionItem(4, 50, 'okay');
            } else if (Math.abs(footIndex.z) < Math.abs(heel.z) && Math.abs(footIndex.z) < Math.abs(midfootZ)) {
                // setFirstContact(t('video_analysis.left_foot_index_strike'));
                firstContactRef.current = t('video_analysis.left_foot_index_strike');

                updateAccordionItem(4, 70, 'sub_optimal');
            } else {
                firstContactRef.current = t('video_analysis.left_midfoot_strike');
                // setFirstContact(t('video_analysis.left_midfoot_strike'));
                updateAccordionItem(4, 100, 'good');
            }
        } else if (side === "right") {
            if (Math.abs(heel.z) > Math.abs(footIndex.z) && Math.abs(heel.z) > Math.abs(midfootZ)) {
                // setFirstContact(t('video_analysis.right_heel_strike'));
                firstContactRef.current = t('video_analysis.right_heel_strike');

                updateAccordionItem(4, 50, 'okay');
            } else if (Math.abs(footIndex.z) > Math.abs(heel.z) && Math.abs(footIndex.z) > Math.abs(midfootZ)) {
                // setFirstContact(t('video_analysis.right_foot_index_strike'));
                firstContactRef.current = t('video_analysis.right_foot_index_strike');

                updateAccordionItem(4, 70, 'sub_optimal');
            } else {
                // setFirstContact(t('video_analysis.right_midfoot_strike'));
                firstContactRef.current = t('video_analysis.right_midfoot_strike');

                updateAccordionItem(4, 100, 'good');
            }
        }
    };

    const handleTabChange = (index) => setActiveTab(index);
    const handleToggleAccordion = (id) => {
        setAccordionItems(accordionItems.map((item) => ({
            ...item,
            isOpen: item.id === id ? !item.isOpen : false
        })));
    };
    const toggleLeftSidebar = () => setIsLeftSidebarVisible(!isLeftSidebarVisible);
    const toggleRightSidebar = () => setIsRightSidebarVisible(!isRightSidebarVisible);

    // This useEffect handles the screen resizing for showing/hiding sidebars
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1300) {
                setIsSmallScreen(true);
                setIsLeftSidebarVisible(false);
                setIsRightSidebarVisible(false);
            } else {
                setIsSmallScreen(false);
                setIsLeftSidebarVisible(true);
                setIsRightSidebarVisible(true);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <MainLayout>
            <div className="h-full flex relative">
                <Metrics
                    isVisible={isLeftSidebarVisible}
                    toggleLeftSidebar={toggleLeftSidebar}
                    totalSessionTime={totalSessionTime}
                    isSmallScreen={isSmallScreen}
                    stepLength={stepLengthRef.current}
                    firstContact = {firstContactRef.current}
                    elbowAngle = {elbowAngleRef.current}
                    verticalOscillation = {verticalOscillationRef.current}
                    flightTime = {flightTimeRef.current}
                    frontFootAngle = {frontFootAngleRef.current}
                    backFootAngle = {backFootAngleRef.current}
                    FootContactTime = {footContactTimeRef.current}
                    headAngle={headAngleRef.current}
                    trunkAngle={trunkAngleRef.current}
                    stepCount={stepCountRef.current}
                    // Pass the screen size info to LeftSidebar
                />
                <VideoContent
                    videoSrc={videoSrc}
                    videoRef={videoRef}
                    canvasRef={canvasRef}
                    playbackSpeed={0.25}
                    StepsChart={StepsChart}
                    isSmallScreen={isSmallScreen}
                    videoId={videoId}
                    bookmarks={bookmarks}
                    setBookmarks={setBookmarks}
                />

                <Analysis
                    isSmallScreen={isSmallScreen}
                    isVisible={isRightSidebarVisible}
                    toggleRightSidebar={toggleRightSidebar}
                    accordionItems={accordionItems}
                    handleToggleAccordion={handleToggleAccordion}
                    tabs={[
                        { name: t('video_analysis.experiment_tab'), content: t('video_analysis.experiment_content') },
                        { name: t('video_analysis.exercise_tab'), content: t('video_analysis.exercise_content') },
                    ]}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                />
            </div>
        </MainLayout>
    );
}


export default VideoAnalysis;
