import React from 'react';

const Card = ({ icon, value, label }) => {
    return (
        <div className="bg-secondary dark:bg-secondary shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 dark:border-primaryAccent text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                {icon}
            </div>
            <div className="text-right">
                <p className="text-2xl">{value}</p>
                <p>{label}</p>
            </div>
        </div>
    );
};

export default Card;
