import React, { useState } from 'react';
import CreditCard from '../payment-methods/CreditCard';
import PayPal from '../payment-methods/PayPal';
import ActionButton from '../utils/ActionButton';
import Modal from './Modal';

const PaymentMethodModal = ({
    isModalOpen,
    handleCloseModal,
    newPaymentMethod,
    handleInputChange,
    handleMethodTypeChange,
    handleSetAsDefaultChange,
    setAsDefault,
    handleSubmitPaymentMethod,
    serverError,
	errors,
	setErrors
}) => {
    // const [errors, setErrors] = useState({});

    const handleFormSubmit = (e) => {
		e.preventDefault();

		// Ensure no errors exist before submitting
		const hasErrors = Object.values(errors).some(error => error !== null);

		if (!hasErrors) {
			handleSubmitPaymentMethod(e);  // Proceed only if no errors are present
		} else {
			console.log("Form has validation errors:", errors);
		}
	};

    return (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <h2 className="text-lg font-semibold mb-4">Add New Payment Method</h2>
            <form onSubmit={handleFormSubmit}>
                {/* Payment Type Dropdown */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="method_type">Payment Type</label>
                    <select
                        name="method_type"
                        value={newPaymentMethod.method_type}
                        onChange={handleMethodTypeChange}
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50]"
                    >
                        <option value="credit_card">Credit Card</option>
                        <option value="paypal">PayPal</option>
                    </select>
                </div>

                {/* Render the form fields based on the selected payment type */}
                {newPaymentMethod.method_type === 'credit_card' && (
                    <CreditCard
                        card_number={newPaymentMethod.card_number}
                        expiry_date={newPaymentMethod.expiry_date}
                        cvv={newPaymentMethod.cvv}
                        name_on_card={newPaymentMethod.name_on_card}
                        handleInputChange={handleInputChange}
                        setErrors={setErrors}  // Pass callback to track errors
                        serverError={serverError}
                    />
                )}
				{newPaymentMethod.method_type === 'paypal' && (
                    <PayPal
                        paypal_email={newPaymentMethod.paypal_email}
                        handleInputChange={handleInputChange}
                        serverError={serverError?.paypal_email}
                    />
                )}

                {/* {newPaymentMethod.method_type === 'bank_transfer' && (
                    <BankTransfer
                        bank_account_number={newPaymentMethod.bank_account_number}
                        bank_name={newPaymentMethod.bank_name}
                        routing_number={newPaymentMethod.routing_number}
                        handleInputChange={handleInputChange}
                    />
                )} */}

                <div className="my-4">
                    <label className="block text-gray-700 mb-2" htmlFor="set_default">
                        <input
                            type="checkbox"
                            name="set_default"
                            checked={setAsDefault}
                            onChange={handleSetAsDefaultChange}
                            className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                        />
                        <span className="ml-1"> Set as Default </span>
                    </label>
                </div>

                {/* Submit Button */}
                <div className="flex justify-end">
                    <ActionButton
                        label="Add Payment Method"
                        type="submit"
                        disabled={Object.values(errors).some(error => error !== null)}  // Disable if there are errors
                        customStyles={`${Object.values(errors).some(error => error !== null) ? 'cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'}`}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default PaymentMethodModal;
