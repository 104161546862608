import React from 'react';
import Accordion from './Accordion'; // Adjust the path as needed
import SidebarHeader from './SidebarHeader';

const Analysis = ({
    isVisible,
    toggleRightSidebar,
    accordionItems,
    handleToggleAccordion,
    tabs,
    activeTab,
    handleTabChange,
    isSmallScreen,
}) => {
    return (
        <div
            className={`${
                isVisible
                    ? isSmallScreen
                        ? 'absolute w-96 z-40 right-0 h-full'
                        : 'w-3/12'
                    : isSmallScreen
                    ? 'absolute w-15 z-40 right-0 h-full'
                    : 'w-0.5/12'
            } transition-all duration-300`}
        >
            <div className="menu_item menu_right">

                <SidebarHeader
                    isVisible={isVisible}
                    title="Analysis"
                    onToggleSidebar={toggleRightSidebar}
                    arrowDirection="right"
                    iconAlt="right arrow icon"
                    reverseOrder={false}
                />

                <Accordion
                    items={accordionItems}
                    isVisible={isVisible}
                    handleToggleAccordion={handleToggleAccordion}
                    tabs={tabs}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                />
            </div>
        </div>
    );
};

export default Analysis;
