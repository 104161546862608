import React, { useState, useEffect } from 'react';
import valid from 'card-validator'; // Import card-validator for validation
import InputFieldLight from '../utils/InputFieldLight';

const CreditCard = ({
    card_number,
    expiry_date,
    cvv,
    name_on_card,
    handleInputChange,
    serverError,
    setErrors // Callback to send errors back to the parent
}) => {
    // Client-side validation state
    const [cardError, setCardError] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [cvvError, setCvvError] = useState('');
    const [nameError, setNameError] = useState('');

    // Track whether fields have been interacted with (touched)
    const [touched, setTouched] = useState({
        name_on_card: false,
        card_number: false,
        expiry_date: false,
        cvv: false
    });

    // Format card number by automatically adding a space after every 4 digits
    const formatCardNumber = (value) => {
        return value.replace(/\s?/g, '').replace(/(\d{4})/g, '$1 ').trim();
    };

    // Format expiry date with MM/YY
    const formatExpiryDate = (value) => {
        const sanitizedValue = value.replace(/\D/g, ''); // Remove non-digit characters
        if (sanitizedValue.length >= 2) {
            return sanitizedValue.slice(0, 2) + '/' + sanitizedValue.slice(2, 4); // Add slash after MM
        }
        return sanitizedValue; // Return only sanitized value if less than 2 digits
    };

    // Validate card number
    const validateCardNumber = () => {
        const cardValidation = valid.number(card_number.replace(/\s+/g, ''));
        if (!card_number) {
			setCardError('card number is required');
            return 'card number is required';
		}
		else if (!cardValidation.isValid) {
            setCardError('Invalid card number');
            return 'Invalid card number';
        } else {
            setCardError(null);
            return null;
        }
    };

    // Validate expiry date
    const validateExpiryDate = () => {
        const expiryValidation = valid.expirationDate(expiry_date);
        if (!expiry_date) {
			setExpiryError('expiry date is required');
            return 'expiry date is required';
		}
		else if (expiry_date && !expiryValidation.isValid) {
            setExpiryError('Invalid expiry date');
            return 'Invalid expiry date';
        } else {
            setExpiryError(null);
            return null;
        }
    };

    // Validate CVV
    const validateCvv = () => {
        const cvvValidation = valid.cvv(cvv);
		if (!cvv) {
			setCvvError('cvv is required');
            return 'cvv is required';
		}
        else if (cvv && !cvvValidation.isValid) {
            setCvvError('Invalid CVV');
            return 'Invalid CVV';
        } else {
            setCvvError(null);
            return null;
        }
    };

    // Validate name on card
    const validateNameOnCard = () => {
		if (!name_on_card) {
			setNameError('Name on card is required');
            return 'Name on card is required';
		}
        else if (name_on_card && !name_on_card.trim()) {
            setNameError('Name on card is required');
            return 'Name on card is required';
        } else {
            setNameError(null);
            return null;
        }
    };

    // Handle when the user interacts with (touches) an input field
    const handleBlur = (field) => {
        setTouched({
            ...touched,
            [field]: true
        });
    };

    // Run validations and track errors only after interaction (blur)
    useEffect(() => {
        const newErrors = {};
        if (touched.name_on_card) newErrors.name_on_card = validateNameOnCard();
        if (touched.card_number) newErrors.card_number = validateCardNumber();
        if (touched.expiry_date) newErrors.expiry_date = validateExpiryDate();
        if (touched.cvv) newErrors.cvv = validateCvv();

        // Send errors to the parent component
        setErrors(newErrors);
    }, [card_number, expiry_date, cvv, name_on_card, touched]);

    return (
        <div className="payment-form space-y-4">
            <div className="card-logos flex mb-4 space-x-2">
                <img src={'/payment-card-icons/visa.svg'} alt="Visa" className="w-10 h-6" />
                <img src={'/payment-card-icons/mastercard.svg'} alt="MasterCard" className="w-10 h-6" />
                <img src={'/payment-card-icons/discover.svg'} alt="Discover" className="w-10 h-6" />
                <img src={'/payment-card-icons/jcb.svg'} alt="JCB" className="w-10 h-6" />
            </div>

            {/* Name on Card */}
            <InputFieldLight
                type="text"
                name="name_on_card"
                value={name_on_card}
                onChange={handleInputChange}
                onBlur={() => handleBlur('name_on_card')}
                placeholder="Name on card"
                autoFocus
                required
                className={`${touched.name_on_card && nameError ? 'border-red-500' : ''}`}
                errorMessage={touched.name_on_card && (nameError || (serverError?.name_on_card && serverError.name_on_card[0]))}
            />

            {/* Card Number */}
            <InputFieldLight
                type="text"
                name="card_number"
                value={formatCardNumber(card_number)} // Card formatting before validation
                onChange={handleInputChange}
                onBlur={() => handleBlur('card_number')}
                required
                maxLength="19" // 16 digits + 3 spaces
                placeholder="1234 5678 9012 3456"
                className={`${touched.card_number && cardError ? 'border-red-500' : ''}`}
                errorMessage={touched.card_number && (cardError || (serverError?.card_number && serverError.card_number[0]))}
            />

            <div className="flex space-x-4">
                {/* Expiry Date */}
                <InputFieldLight
                    type="text"
                    name="expiry_date"
                    value={formatExpiryDate(expiry_date)} // Format expiry date before validation
                    onChange={handleInputChange}
                    onBlur={() => handleBlur('expiry_date')}
                    maxLength="5" // MM/YY
                    placeholder="MM/YY"
                    required
                    className={`${touched.expiry_date && expiryError ? 'border-red-500' : ''}`}
                    errorMessage={touched.expiry_date && (expiryError || (serverError?.expiry_date && serverError.expiry_date[0]))}
                    wrapperClass="flex-1"
                />

                {/* CVV */}
                <InputFieldLight
                    type="text"
                    name="cvv"
                    value={cvv}
                    onChange={handleInputChange}
                    onBlur={() => handleBlur('cvv')}
                    maxLength="3"
                    placeholder="CVC"
                    required
                    className={`${touched.cvv && cvvError ? 'border-red-500' : ''}`}
                    errorMessage={touched.cvv && (cvvError || (serverError?.cvv && serverError.cvv[0]))}
                    wrapperClass="flex-1"
                />
            </div>
        </div>
    );
};

export default CreditCard;
