import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import MainLayout from '../components/layouts/MainLayout';

import { AppContext } from '../AppContext';
import MediaExplorer from '../components/home/MediaExplorer';
import FolderModal from '../components/modals/FolderModal';
import Breadcrumb from '../components/utils/Breadcrumb';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../components/utils/LoadingSpinner';
import FloatingActionButton from '../components/utils/FloatingActionButton';
import { generateBreadcrumb } from '../services/breadcrumbService';
// Import services
import { fetchFoldersByParent, createFolder } from '../services/folderService';
import { fetchVideosByFolder } from '../services/videoService';

function Home() {
    const { folderId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { setFolderId } = useContext(AppContext);
    const [folders, setFolders] = useState([]);
    const [videos, setVideos] = useState([]);
    const [currentParent, setCurrentParent] = useState(folderId || null);
    const [folderPath, setFolderPath] = useState([{ id: null, name: 'My Dashboard' }]);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [editingFolder, setEditingFolder] = useState(null);

    const handleEditFolder = (folder) => {
        setEditingFolder(folder); // Set the folder being edited
        setIsFolderModalOpen(true); // Open the modal
    };

    useEffect(() => {
        if (folderId) {
            setCurrentParent(folderId);
        } else {
            setCurrentParent(null);
            setFolderPath([{ id: null, name: t('folder_management.dashboard') }]);
        }
    }, [folderId, t]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const foldersResponse = await fetchFoldersByParent(currentParent);
                const videosResponse = await fetchVideosByFolder(currentParent);
                setFolders(foldersResponse);
                setVideos(videosResponse);
                const breadcrumb = await generateBreadcrumb(currentParent, foldersResponse, t('folder_management.dashboard'));
                setFolderPath(breadcrumb);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        setFolderId(currentParent);
        fetchData();
    }, [currentParent, setFolderId]);

    const handleCreateFolder = async (folderData) => {
        try {
            const newFolder = await createFolder(folderData, currentParent); // Use service
            setFolders((prevFolders) => [...prevFolders, newFolder]);
            setIsFolderModalOpen(false);
        } catch (error) {
            console.error('Error creating folder:', error);
            throw new Error(t('folder_management.create_folder_error_generic'));
        }
    };

    const handleViewFolder = (folderId) => {
        navigate(`/folder/${folderId}`);
        setCurrentParent(folderId);
    };

    const handleNavigateBreadcrumb = (folderId) => {
        setCurrentParent(folderId);
        const folderIndex = folderPath.findIndex(folder => folder.id === folderId);
        setFolderPath(folderPath.slice(0, folderIndex + 1));
        navigate(folderId === null ? '/home' : `/folder/${folderId}`);
    };

    return (
        <MainLayout
            onCreateFolder={() => setIsFolderModalOpen(true)}
            className="px-5" // Pass any additional class names for styling
        >
            <div className="h-full">
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <LoadingSpinner />
                    </div>
                ) : (
                    <>
                        <Breadcrumb folderPath={folderPath} onNavigate={handleNavigateBreadcrumb} />
                        <MediaExplorer
                            folders={folders}
                            videos={videos}
                            onViewFolder={handleViewFolder}
                            setFolders={setFolders}
                            setVideos={setVideos}
                        />
                    </>
                )}
            </div>
            {(location.pathname === '/home' || location.pathname.startsWith('/folder/')) && (
                <FloatingActionButton onClick={() => setIsFolderModalOpen(true)} />
            )}
            <FolderModal
                isOpen={isFolderModalOpen}
                onClose={() => {
                    setIsFolderModalOpen(false);
                    setEditingFolder(null); // Clear the folder state when closing
                }}
                onCreate={handleCreateFolder}
                folder={editingFolder} // Pass the folder for editing
            />

        </MainLayout>
    );
}

export default Home;
