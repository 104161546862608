import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { BASE_URL } from '../../config';
import { useTranslation } from 'react-i18next';

import { convertHeight, convertWeight } from '../../utils/unitConversions';
import PasswordChangeModal from '../../components/modals/PasswordChangeModal';
import ActionButton from '../../components/utils/ActionButton';
import PageContainer from '../../components/layouts/PageContainer';


function UserProfilePage() {
    const { t } = useTranslation();
    const { user, profile } = useContext(AppContext);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleEditProfile = () => {
        navigate('/edit-profile');
    };

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            setErrorMessage(t('profile.passwords_do_not_match'));
            return;
        }

        setLoading(true);
        setErrorMessage('');

        try {
            const response = await axiosInstance.put(`${BASE_URL}/api/auth/change-password/`, {
                old_password: oldPassword,
                new_password: newPassword,
            });

            if (response.status === 200) {
                alert(t('profile.password_change_success'));
                setShowModal(false);
            }
        } catch (error) {
            if (error.response) {
                const errorData = error.response.data;
                if (error.response.status === 400) {
                    if (errorData.error) {
                        setErrorMessage(errorData.error);
                    } else if (errorData.old_password) {
                        setErrorMessage(errorData.old_password[0]);
                    } else if (errorData.new_password) {
                        setErrorMessage(errorData.new_password[0]);
                    } else {
                        setErrorMessage(t('profile.password_change_failure'));
                    }
                } else {
                    setErrorMessage(t('general.unexpected_error'));
                }
            } else {
                setErrorMessage(t('profile.password_change_network_failure'));
            }
        }

        setLoading(false);
    };

    return (
        <PageContainer>
            <div className='p-6 bg-[#2D2D2D] shadow-lg rounded-md'>
                <h2 className="text-2xl font-semibold text-white mb-6">{t('profile.my_profile')}</h2>
                <div className="flex flex-col lg:flex-row">
                    <div className="flex flex-col items-center justify-center p-5 pt-0 w-full lg:w-1/4">
                        <img
                            src={profile.profile_picture ? `${BASE_URL}/${profile.profile_picture}` : 'https://via.placeholder.com/150'}
                            alt={t('profile.profile_picture_alt')}
                            className="w-32 h-32 rounded-md object-cover mb-4"
                        />
                        <p className="text-white text-sm">
                            {profile.user_type === 'trainer' ? t('profile.trainer') : t('profile.single_user')}
                        </p>
                    </div>

                    <div className="flex-1 mt-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.first_name')}</label>
                                <p className="text-white">{user.first_name}</p>
                            </div>

                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.last_name')}</label>
                                <p className="text-white">{user.last_name}</p>
                            </div>

                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.email')}</label>
                                <p className="text-white">{user.email}</p>
                            </div>

                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.height')}</label>
                                <p className="text-white">
                                    {convertHeight(profile.height, profile.height_unit)} {profile.height_unit}
                                </p>
                            </div>

                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.weight')}</label>
                                <p className="text-white">
                                    {convertWeight(profile.weight, profile.weight_unit)} {profile.weight_unit}
                                </p>
                            </div>

                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.address')}</label>
                                <p className="text-white">{profile.address}</p>
                            </div>

                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.postcode')}</label>
                                <p className="text-white">{profile.postcode}</p>
                            </div>

                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.town')}</label>
                                <p className="text-white">{profile.town}</p>
                            </div>

                            <div>
                                <label className="block text-gray-400 text-sm mb-2">{t('profile.country')}</label>
                                <p className="text-white">{profile.country}</p>
                            </div>
                        </div>

                        <div className="flex justify-end mt-6 space-x-4">
                            <ActionButton
                                onClick={() => setShowModal(true)}
                                label={t('profile.change_password')}
                            />

                            <ActionButton
                                onClick={handleEditProfile}
                                label={t('profile.edit_profile')}
                            />
                        </div>
                    </div>
                </div>

                {showModal && (
                    <PasswordChangeModal
                        isOpen={showModal}
                        onClose={() => setShowModal(false)} // Close modal
                        oldPassword={oldPassword}
                        setOldPassword={setOldPassword}
                        newPassword={newPassword}
                        setNewPassword={setNewPassword}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        errorMessage={errorMessage}
                        handlePasswordChange={handlePasswordChange}
                        loading={loading}
                    />
                )}

            </div>
        </PageContainer>
    );
}

export default UserProfilePage;
