import React from 'react';

const SidebarHeader = ({
    isVisible,
    title,
    onToggleSidebar,
    arrowDirection,
    iconAlt,
    reverseOrder = false,
}) => {
    const content = [
        <img
            key="arrow"
            className={`h-6 w-6 head_icon cursor-pointer transform transition-transform duration-300 ${
                isVisible ? '' : 'rotate-180'
            }`}
            src={`/utils/ar-${arrowDirection}.svg`}
            alt={iconAlt}
            onClick={onToggleSidebar}
        />,
        isVisible && (
            <div key="title" className="text-white">
                {title}
            </div>
        ),
    ];

    return (
        <div className="flex justify-between mb-5 pt-2.5">
            {reverseOrder ? content.reverse() : content}
        </div>
    );
};

export default SidebarHeader;
