import React, { useState } from 'react';
import InputFieldLight from '../utils/InputFieldLight';
import ActionButton from '../utils/ActionButton';
import Modal from './Modal'; // Import the base Modal
import { useTranslation } from 'react-i18next';

const RunnerModal = ({
    isOpen, // Modal visibility
    onClose, // Close modal handler
    formData,
    setFormData,
    isEditing,
    editingRunnerId,
    setRunners,
    handleSubmit,
}) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        try {
            await handleSubmit(); // Call the parent-provided submission logic
            onClose(); // Close modal on successful submission
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data); // Set field-specific errors
            } else {
                console.error(t('runner.save_error'), error);
            }
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2 className="text-lg font-semibold mb-4">
                {isEditing ? t('runner.edit_runner') : t('runner.add_runner')}
            </h2>
            <form onSubmit={handleFormSubmit}>
                <div className="mb-4">
                    <label className="block text-sm mb-2">{t('runner.name_label')}</label>
                    <InputFieldLight
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={t('runner.name_placeholder')}
                        required
                        autoFocus
                    />
                    {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-sm mb-2">{t('runner.height_label')}</label>
                    <InputFieldLight
                        name="height"
                        type="number"
                        value={formData.height}
                        onChange={handleChange}
                        placeholder={t('runner.height_placeholder')}
                        required
                    />
                    {errors.height && <p className="text-red-500 text-xs mt-1">{errors.height}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-sm mb-2">{t('runner.weight_label')}</label>
                    <InputFieldLight
                        name="weight"
                        type="number"
                        value={formData.weight}
                        onChange={handleChange}
                        placeholder={t('runner.weight_placeholder')}
                        required
                    />
                    {errors.weight && <p className="text-red-500 text-xs mt-1">{errors.weight}</p>}
                </div>

                <div className="flex justify-end space-x-4">
                    <ActionButton
                        type="submit"
                        label={isEditing ? t('runner.save_changes') : t('runner.submit')}
                        customStyles="bg-primaryAccent hover:bg-primaryAccentHover text-white px-4 py-2 rounded-md"
                    />
                </div>
            </form>
        </Modal>
    );
};

export default RunnerModal;
