import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './Modal'; // Import the base Modal component

function FolderModal({ isOpen, onClose, onCreate, folder = null }) {
    const { t } = useTranslation();
    const [name, setName] = useState(folder ? folder.name : ''); // Pre-fill the name if folder is passed
    const [error, setError] = useState(''); // State to track and display errors
    const inputRef = useRef(null); // Create a ref for the input field


    useEffect(() => {
        if (isOpen) {
            setName(folder ? folder.name : ''); // Reset name when the modal opens
            setError(''); // Clear errors
            inputRef.current?.focus(); // Automatically focus the input field
        }
    }, [isOpen, folder]); // Re-run the effect when folder changes
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Reset error before submitting

        try {
            if (folder) {
                await onCreate(folder.id, name); // Pass the folder ID and the updated name
            } else {
                await onCreate({ name }); // Otherwise, it's a new folder creation
            }
        } catch (serverError) {
            setError(serverError.message);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2 className="text-xl font-bold mb-4">
                {folder ? t('folder_management.rename_folder') : t('folder_management.create_new_folder')}
            </h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">{t('folder_management.folder_name')}</label>
                    <input
                        type="text"
                        value={name}
                        ref={inputRef} // Attach the ref to the input field
                        onChange={(e) => setName(e.target.value)}
                        className={`mt-1 block w-full border border-gray-300 focus:border-primaryAccent focus:outline-none shadow-sm p-1 ${
                            error ? 'border-red-500' : ''
                        }`}
                        required
                    />
                    {error && (
                        <p className="text-red-500 text-sm mt-1">{t('folder_management.error_message')}</p>
                    )}
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="bg-primaryAccent text-white px-3 py-1 ml-2 rounded-md hover:bg-primaryAccentHover transition"
                    >
                        {folder ? t('general.update') : t('general.create')}
                    </button>
                </div>
            </form>
        </Modal>
    );
}

export default FolderModal;
