import React from 'react';

const Icon = ({ name, additionalClasses = "" }) => {
    return (
        <i
            className={`fas fa-${name} ${additionalClasses}`}
        ></i>
    );
};

export default Icon;
