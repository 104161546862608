import React from 'react';

const AccordionItem = ({ item, isVisible, handleToggleAccordion, tabs, activeTab, handleTabChange }) => (
    <div className="accordion-in mt-2">
        <div className="flex justify-between items-center bg-secondary p-4 mb-2 rounded-md">
            {/* Always show percentage indicator */}
            <div className="r-auto p-1 flex items-center">
                <div className={`progress ${item.status === 'Bad' ? 'red' : ''}`}>
                    <span className="progress-left">
                        <span className={`progress-bar border-primaryAccent ${item.status === 'Bad' ? 'ten' : ''}`}></span>
                    </span>
                    <span className="progress-right">
                        <span className={`progress-bar border-primaryAccent ${item.status === 'Bad' ? 'ten' : ''}`}></span>
                    </span>
                    <div className="progress-value">{item.percentage}%</div>
                </div>
                {isVisible && (
                    <div className="flex items-center ml-2">
                        <span className="text-white">{item.name}</span>
                    </div>
                )}
            </div>

            {/* Show name, status, and arrow only if sidebar is visible */}
            {isVisible && (
                <div className="flex items-center space-x-2 flex-shrink-0">
                    <div
                        className="accordion-header flex items-center cursor-pointer"
                        onClick={() => handleToggleAccordion(item.id)}
                    >
                        <span
                            className={`inline-block px-3 py-1.5 text-center whitespace-normal leading-tight align-baseline rounded-full text-xs font-bold ${
                                item.status === 'Bad' ? 'bg-red-500' : 'bg-primaryAccent'
                            } text-white`}
                        >
                            {item.status}
                        </span>
                        <svg
                            className={`w-5 h-5 ${item.isOpen ? 'rotate-180' : ''} ml-2 flex-shrink-0`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
            )}
        </div>

        {/* Accordion content */}
        {isVisible && item.isOpen && (
            <div className="p-4 accordion-body mt-2 rounded-md">
                <div>
                    <img className="tab_head_img" src={'/utils/run.png'} alt="img" />
                </div>
                <div className="block p-5">
                    <ul className="acc_tab mt-2 nav nav-tabs flex justify-center" role="tablist">
                        {tabs.map((tab, index) => (
                            <li className="nav-item" key={`tab_${index}`}>
                                <button
                                    onClick={() => handleTabChange(index)}
                                    className={`nav-link ${activeTab === index ? 'active' : ''}`}
                                    type="button"
                                >
                                    {tab.name}
                                </button>
                            </li>
                        ))}
                    </ul>

                    <div className="tab-content">
                        <div className="fade tab-pane active show">
                            <div>
                                <div className="mt-3">
                                    <h6 className="text-primaryAccent text-base font-semibold">Current Position</h6>
                                    <p className="text-graySoft text-sm">Sub head</p>
                                    <p className="text-gray-400 text-sm">
                                        Lorem ipsum is placeholder text commonly used in the graphic, print, and
                                        publishing industries for previewing layouts and visual mockups.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="fade tab-pane">
                            <div>This tab's content is never seen</div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
);

export default AccordionItem;
