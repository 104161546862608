import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { BASE_URL } from '../../config';
import axios from 'axios';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import InputFieldLight from '../../components/utils/InputFieldLight';
import ActionButton from '../../components/utils/ActionButton';
import DividerWithText from '../../components/utils/DividerWithText';
import GoogleLoginButton from '../../components/utils/GoogleLoginButton';

// Assuming you have a translation hook or function
import { useTranslation } from 'react-i18next';

function Register() {
	const { t } = useTranslation(); // Initialize translation hook
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isTrainer, setIsTrainer] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const response = await axios.post(
				`${BASE_URL}/api/auth/register/`,
				{
					email,
					password,
					is_trainer: isTrainer,
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			if (response.status === 201) {
				navigate('/verify-email');
			} else {
				setErrorMessage(response.data.error || t('authentication.registration_failed'));
			}
		} catch (error) {
			setErrorMessage(error.response?.data?.error || t('authentication.error_registering'));
			console.error('Error registering user', error);
		} finally {
			setLoading(false);
		}
	};

	const handleCheckboxChange = (e) => {
		setIsTrainer(e.target.checked);
	};

	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<form onSubmit={handleSubmit} className="w-full max-w-md bg-white p-8 rounded shadow-md space-y-4">
				<h2 className="text-2xl font-bold mb-6 text-center text-primaryAccent">{t('authentication.register')}</h2>
				{errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

				<InputFieldLight
					type="email"
					placeholder={t('authentication.email')}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className="mb-4"
					required
				/>
				<InputFieldLight
					type="password"
					placeholder={t('authentication.password')}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
				/>

				<div className="flex items-center mb-4">
					<input
						type="checkbox"
						id="isTrainer"
						checked={isTrainer}
						onChange={handleCheckboxChange}
						className="h-4 w-4 text-primaryAccent focus:ring-primaryAccent border-gray-300 rounded"
					/>
					<label htmlFor="isTrainer" className="ml-2 text-sm text-gray-700">
						{t('runner.are_you_trainer')}
					</label>
				</div>

				<ActionButton
					onClick={handleSubmit}
					label={loading ? <LoadingSpinner color="text-white"/> : t('authentication.register')}
					type="submit"
					customStyles={`w-full flex items-center justify-center text-white py-2 focus:outline-none ${loading ? 'cursor-not-allowed' : ''}`}
					disabled={loading}
				/>

				<p className="text-center mt-4">
					{t('authentication.already_have_account')} {' '}
					<Link to="/login" className="text-blue-500 hover:underline">
						{t('authentication.login')}
					</Link>
				</p>

				<div className="text-center mt-4">
					<DividerWithText text={t('general.or')} />
					<GoogleLoginButton label={t('authentication.signup_with_google')} />
				</div>
			</form>
		</div>
	);
}

export default Register;
