import { getFolderDetails } from './folderService';

export const generateBreadcrumb = async (folderId, folders, defaultName) => {
    const path = [];
    let currentFolderId = folderId;
    let currentFolder = folders.find(folder => folder.id === currentFolderId);

    while (currentFolderId) {
        if (!currentFolder) {
            try {
                const fetchedFolder = await getFolderDetails(currentFolderId);
                currentFolder = fetchedFolder;
            } catch (error) {
                console.error(`Error fetching folder details for breadcrumb:`, error);
                break;
            }
        }
        path.push({ id: currentFolder.id, name: currentFolder.name });
        currentFolderId = currentFolder.parent;
        currentFolder = folders.find(folder => folder.id === currentFolderId);
    }
    path.reverse();
    path.unshift({ id: null, name: defaultName });
    return path;
};
