import React, { useState, useRef, useContext } from 'react';
import { BASE_URL } from '../../config';
import { AppContext } from '../../AppContext';
import axiosInstance from '../../axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../components/layouts/MainLayout';

function VideoUpload() {
    const { t } = useTranslation();
    const { folderId } = useContext(AppContext);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [previewVideo, setPreviewVideo] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);

    const navigate = useNavigate();

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        handleVideoFile(file);
    };

    const handleVideoFile = (file) => {
        if (file && (file.type === 'video/mp4' || file.type === 'video/quicktime')) {
            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            videoElement.onloadedmetadata = () => {
                const duration = videoElement.duration;

                if (duration <= 120) {
                    setSelectedVideo(file);
                    const videoURL = URL.createObjectURL(file);
                    setPreviewVideo(videoURL);
                    setIsUploaded(false);
                } else {
                    alert(t('videos.video_too_long'));
                }

                URL.revokeObjectURL(videoElement.src);
            };
        } else {
            alert(t('videos.invalid_format'));
        }
    };

    const handleUpload = async () => {
        if (!selectedVideo) {
            alert(t('videos.no_video_selected'));
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedVideo);
        formData.append('folder', folderId);

        try {
            setIsLoading(true);

            const response = await axiosInstance.post(
                `${BASE_URL}/api/videos/upload/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadPercentage(percentage);
                    }
                }
            );

            if (response.status === 201) {
                setSelectedVideo(null);
                setPreviewVideo(null);
                setIsUploaded(true);
                fileInputRef.current.value = '';
                setUploadPercentage(0);

                if (folderId === null) {
                    navigate('/home');
                } else {
                    navigate(`/folder/${folderId}`);
                }
            } else {
                alert(`${t('videos.upload_failed')}: ${response.data.message}`);
            }
        } catch (error) {
            const serverMessage = error.response?.data?.error || error.response?.data?.message || t('videos.upload_error');
            alert(`${t('videos.upload_failed')}: ${serverMessage}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChooseAnotherVideo = () => {
        setSelectedVideo(null);
        setPreviewVideo(null);
        fileInputRef.current.value = '';
        setIsUploaded(false);
        setUploadPercentage(0);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);

        const file = event.dataTransfer.files[0];
        handleVideoFile(file);
    };

    return (
        <MainLayout>
            <div
                className={`h-full px-4 py-6 flex justify-center items-center ${isDragging ? 'bg-secondary' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <div className="border-dashed border-2 border-gray-400 rounded-lg w-2/3 p-10 text-center">
                    {previewVideo ? (
                        <div className="w-full h-full flex flex-col justify-center items-center overflow-hidden">
                            <video className="mb-4 w-auto h-[480px] max-w-full" controls>
                                <source src={previewVideo} type="video/mp4" />
                                {t('videos.no_support')}
                            </video>
                        </div>
                    ) : (
                        <>
                            <p className="text-gray-500 text-xl mb-4">{t('videos.drag_drop')}</p>
                            <p className="text-gray-500 mb-6">{t('videos.accepted_formats')}</p>
                        </>
                    )}
                    <input
                        type="file"
                        accept="video/mp4, video/quicktime"
                        id="videoUpload"
                        className="hidden"
                        onChange={handleFileUpload}
                        ref={fileInputRef}
                    />

                    <div className="flex flex-col items-center">
                        <button
                            className={`bg-primaryAccent text-white px-4 py-2 rounded mb-2 ${isLoading ? 'cursor-not-allowed' : ''}`}
                            onClick={selectedVideo ? handleUpload : () => fileInputRef.current.click()}
                            disabled={isLoading}
                        >
                            {isLoading ? `${uploadPercentage}% ${t('videos.uploading')}` : selectedVideo ? t('videos.upload_video') : t('videos.choose_video')}
                        </button>

                        {selectedVideo && !isLoading && (
                            <button
                                className="text-blue-500 underline"
                                onClick={handleChooseAnotherVideo}
                            >
                                {t('videos.choose_another')}
                            </button>
                        )}

                        {isLoading && (
                            <div className="w-full bg-gray-200 h-2 rounded mt-4">
                                <div
                                    className="bg-primaryAccent h-2 rounded"
                                    style={{ width: `${uploadPercentage}%` }}
                                ></div>
                            </div>
                        )}

                        {isUploaded && !isLoading && (
                            <div className="flex items-center mt-4">
                                <div className="w-8 h-8 rounded-full border-4 border-primaryAccent"></div>
                                <span className="ml-2 text-primaryAccent">{t('videos.upload_complete')}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default VideoUpload;
