import React from 'react';
import Header from './Header'; // Adjust the path to your Header component

const MainLayout = ({ children, onCreateFolder, className = '' }) => {
  return (
    <div>
      <Header onCreateFolder={onCreateFolder} />
      <hr className="border-graySoft" />

      <main className={`pt-14 ${className}`}>
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
