import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Modal from './Modal';

const LanguageModal = ({ isOpen, onClose }) => {
	const { t } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div>
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-lg font-semibold">{t('general.choose_language')}</h2>
				</div>
				<p className="text-sm text-gray-500 mb-4">{t('general.language_modal_info')}</p>
				<div className="grid grid-cols-2 gap-4">
					<button
						onClick={() => changeLanguage('en')}
						className="flex flex-col items-start px-2 py-1 text-sm hover:bg-gray-100 rounded"
					>
						<span className="font-bold">English</span>
						<span className="text-gray-500">{t('general.language_english')}</span>
					</button>
					<button
						onClick={() => changeLanguage('de')}
						className="flex flex-col items-start px-2 py-1 text-sm hover:bg-gray-100 rounded"
					>
						<span className="font-bold">Deutsch</span>
						<span className="text-gray-500">{t('general.language_german')}</span>
					</button>
					<button
						onClick={() => changeLanguage('fr')}
						className="flex flex-col items-start px-2 py-1 text-sm hover:bg-gray-100 rounded"
					>
						<span className="font-bold">Français</span>
						<span className="text-gray-500">{t('general.language_french')}</span>
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default LanguageModal;
