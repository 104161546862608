import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar
} from "recharts";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import MainLayout from '../../components/layouts/MainLayout';

const SessionAnalysis = () => {
    const { sessionId } = useParams();
    const [snapshots, setSnapshots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [graphData, setGraphData] = useState([]);
    const [graphSummary, setGraphSummary] = useState({
        averageFlightTime: "N/A",
        maxVerticalOscillation: "N/A",
    });


    useEffect(() => {
        const fetchSnapshots = async () => {
            try {
                const response = await axiosInstance.get(`/api/sessions/${sessionId}/snapshots/`);
                const data = response.data;

                setSnapshots(data);
                setLoading(false);

                // Prepare graph data
                const processedData = data.map((snapshot) => ({
                    timestamp: `${snapshot.timestamp}s`,
                    flightTime: snapshot.metrics.flightTime
                        ? parseFloat(snapshot.metrics.flightTime)
                        : null,
                    verticalOscillation: snapshot.metrics.verticalOscillation
                        ? parseFloat(snapshot.metrics.verticalOscillation)
                        : null,
                }));
                setGraphData(processedData);

                // Calculate graph summary
                const flightTimeValues = processedData
                    .filter((d) => d.flightTime !== null)
                    .map((d) => d.flightTime);
                const verticalOscillationValues = processedData
                    .filter((d) => d.verticalOscillation !== null)
                    .map((d) => d.verticalOscillation);

                const averageFlightTime =
                    flightTimeValues.reduce((acc, val) => acc + val, 0) / flightTimeValues.length || 0;
                const maxVerticalOscillation = Math.max(...verticalOscillationValues) || 0;

                setGraphSummary({
                    averageFlightTime: `${averageFlightTime.toFixed(2)}s`,
                    maxVerticalOscillation: `${maxVerticalOscillation.toFixed(2)}m`,
                });
            } catch (err) {
                setError(err.message || "Error fetching snapshots");
                setLoading(false);
            }
        };


        fetchSnapshots();
    }, [sessionId]);

    if (loading) {
        return <div className="text-gray-500 text-xl mb-4 text-center">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center">Error: {error}</div>;
    }


    return (
        <MainLayout>
            <div className="h-full px-4 py-6 flex justify-between items-start">
                {/* Metric Cards */}
                <div className="w-1/2 pr-6">
                    {snapshots.map((snapshot) => (
                        <div
                            key={snapshot.id}
                            className="bg-secondary text-white shadow-md rounded-lg mb-6 p-6"
                            style={{ borderLeft: "4px solid #3E8E41" }}
                        >
                            <h3 className="text-xl font-bold mb-4">
                                Timestamp: <span className="text-primaryAccent">{snapshot.timestamp}s</span>
                            </h3>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.entries(snapshot.metrics).map(([key, value]) => (
                                    <div
                                        key={key}
                                        className="flex items-center justify-between p-4 bg-primary rounded shadow-md"
                                    >
                                        <span className="font-semibold text-gray-300">
                                            {key.replace(/([A-Z])/g, " $1")}
                                        </span>
                                        <span
                                            className={`text-lg font-bold ${
                                                value !== null ? "text-primaryAccent" : "text-gray-500"
                                            }`}
                                        >
                                            {value !== null ? value : "N/A"}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Graph Section */}
                <div className="w-1/2 bg-secondary text-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-bold mb-4">Performance Insights</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={graphData} margin={{ top: 20, right: 20, bottom: 20, left: 0 }}>
                            <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                            <XAxis dataKey="timestamp" stroke="#ccc" />
                            <YAxis stroke="#ccc" />
                            <Tooltip
                                contentStyle={{ backgroundColor: "#333", border: "none" }}
                                labelStyle={{ color: "#fff" }}
                                formatter={(value) => `${value}`}
                            />
                            <Legend wrapperStyle={{ color: "#ccc" }} />
                            <Line
                                type="monotone"
                                dataKey="flightTime"
                                stroke="#4caf50"
                                name="Flight Time (s)"
                                dot={{ r: 5 }}
                                activeDot={{ r: 8 }}
                            />
                            <Line
                                type="monotone"
                                dataKey="verticalOscillation"
                                stroke="#2196f3"
                                name="Vertical Oscillation (m)"
                                dot={{ r: 5 }}
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                    <div className="mt-6 bg-primary p-4 rounded shadow-md">
                        <h4 className="text-lg font-bold">Summary</h4>
                        <p>Average Flight Time: {graphSummary.averageFlightTime}</p>
                        <p>Max Vertical Oscillation: {graphSummary.maxVerticalOscillation}</p>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default SessionAnalysis;
