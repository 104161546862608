import React, { useContext, useState, useEffect } from "react";
import { AppContext } from '../../AppContext';
import axiosInstance from '../../axiosInstance';
import Card from "../../components/utils/Card";
import Icon from "../../components/utils/Icon";
import { BASE_URL } from "../../config";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../components/utils/LoadingSpinner';

const MyAccount = () => {
    const { t } = useTranslation();
    const { profile } = useContext(AppContext);
    const [counts, setCounts] = useState({ runners: 0, videos: 0, payment_methods: 0, folders: 0 });
    const [loading, setLoading] = useState(true);

    const fetchCounts = async () => {
        try {
            const response = await axiosInstance.get(`${BASE_URL}/api/get-count/`);
            const data = response.data;
            setCounts({
                runners: data.runners || 0,
                videos: data.videos,
                payment_methods: data.payment_methods,
                folders: data.folders
            });
        } catch (error) {
            console.error('Error fetching counts:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (profile) {
            fetchCounts();
        }
    }, [profile]);

    if (loading) {
        return <LoadingSpinner />;
    }
    const commonClasses = `dark:text-[#3E8E41] text-3xl transform transition-transform duration-500 ease-in-out`;
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 gap-4">
            {profile.user_type === 'trainer' && (
                <Card
                    icon={<Icon name="running" additionalClasses={commonClasses}/>}
                    value={counts.runners}
                    label={t('my_account.runners')}
                />
            )}
            <Card
                icon={<Icon name="video" additionalClasses={commonClasses}/>}
                value={counts.videos}
                label={t('my_account.videos')}
            />
            <Card
                icon={<Icon name="credit-card" additionalClasses={commonClasses}/>}
                value={counts.payment_methods}
                label={t('my_account.payment_methods')}
            />
            <Card
                icon={<Icon name="folder" additionalClasses={commonClasses}/>}
                value={counts.folders}
                label={t('my_account.folders')}
            />
        </div>
    );
};

export default MyAccount;
