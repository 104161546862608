import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function VerifyEmail() {
	const { t } = useTranslation();

	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<div className="w-full max-w-md bg-white p-8 rounded shadow-md">
				<h2 className="text-2xl font-bold mb-6 text-center text-primaryAccent">{t('verify_email.title')}</h2>
					<p className="text-center text-gray-700 mb-6">
						{t('verify_email.message')}
					</p>
				<p className="text-center text-gray-500 mb-6">
						{t('verify_email.did_not_receive')}{' '}
					<Link to="#" className="text-blue-500 hover:underline">{t('verify_email.resend_link')}</Link>
				</p>
					<Link to="/login" className="block text-center bg-primaryAccent text-white py-2 rounded hover:bg-primaryAccentHover focus:outline-none">
						{t('verify_email.go_to_login')}
					</Link>
			</div>
		</div>
	);
}

export default VerifyEmail;
