import React from 'react';
import { Route } from 'react-router-dom';
import PublicRoute from '../components/PublicRoute';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import VerifyEmail from '../pages/auth/VerifyEmail';
import PasswordResetRequest from '../pages/auth/PasswordResetRequest';
import PasswordResetConfirm from '../pages/auth/PasswordResetConfirm';

const PublicRoutes = [
    <Route
        key="login"
        path="/login"
        element={
            <PublicRoute>
                <Login />
            </PublicRoute>
        }
    />,
    <Route
        key="register"
        path="/register"
        element={
            <PublicRoute>
                <Register />
            </PublicRoute>
        }
    />,
    <Route key="verify-email" path="/verify-email" element={<VerifyEmail />} />,
    <Route key="password-reset" path="/password-reset" element={<PasswordResetRequest />} />,
    <Route
        key="password-reset-confirm"
        path="/password-reset-confirm/:uidb64/:token"
        element={<PasswordResetConfirm />}
    />,
];

export default PublicRoutes;
