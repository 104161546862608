import React from 'react';
import { Routes, Route, Navigate  } from 'react-router-dom';

import './index.css';
import './assets/custom.css';

import useDynamicTitle from './hooks/useDynamicTitle';

import ProtectedRoutes from './routes/ProtectedRoutes';
import PublicRoutes from './routes/PublicRoutes';

function App() {
    useDynamicTitle();

    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                {PublicRoutes}
                {ProtectedRoutes}
            </Routes>
        </div>
    );
}

export default App;
