// Function to calculate the angle between three points
export function getAngle(start, middle, end) {
    const radians = Math.atan2(end.y - middle.y, end.x - middle.x) - Math.atan2(start.y - middle.y, start.x - middle.x);
    let angle = Math.abs(radians * 180.0 / Math.PI);
    if (angle > 180.0) {
        angle = 360.0 - angle;
    }
    return angle;
}

export function calcVectorAngle(vector1, vector2) {
    const dotProduct = vector1[0] * vector2[0] + vector1[1] * vector2[1];
    const magnitude1 = Math.sqrt(vector1[0] ** 2 + vector1[1] ** 2);
    const magnitude2 = Math.sqrt(vector2[0] ** 2 + vector2[1] ** 2);

    if (magnitude1 * magnitude2 === 0) {
        return 0;
    }

    const cosTheta = dotProduct / (magnitude1 * magnitude2);
    return Math.degrees(Math.acos(cosTheta));
}

// Helper function to convert radians to degrees
Math.degrees = function(radians) {
    return radians * (180 / Math.PI);
};
