import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../config';
import { useTranslation } from 'react-i18next';

const VideoItem = ({ video, activeVideoMenu, toggleVideoMenu, handleMenuItemClick }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const defaultThumbnailIcon = 'fas fa-video';

    return (
        <div
            key={video.id}
            className="relative bg-secondary video-item w-full sm:w-[calc(50%-1rem)] md:w-[calc(33%-1rem)] lg:w-[calc(25%-1rem)] xl:w-[calc(20%-1rem)] cursor-pointer shadow-md transition-transform transform hover:scale-105"
            onClick={() => navigate(`/video-analysis/${video.id}`)}
            style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}
        >
            <div className="bg-secondary flex items-center justify-center h-40">
                {video.thumbnail ? (
                    <img
                        src={`${BASE_URL}/${video.thumbnail}`}
                        alt={t('videos.thumbnail_alt')}
                        className="w-full h-40 object-cover"
                    />
                ) : (
                    <i className={`${defaultThumbnailIcon} text-primaryAccent text-6xl`}></i>
                )}
            </div>
            <div
                className="p-4 bg-secondary shadow-md"
                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}
            >
                <p className="text-xs text-graySoft">
                    {t('videos.uploaded_on')}: {new Date(video.created_at).toLocaleDateString()}
                </p>
            </div>
            {/* Three-dot menu icon with hover effect */}
            <div className="absolute bottom-2 right-2">
                <button
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering video card click
                        toggleVideoMenu(video.id);
                    }}
                    className="text-white p-2 px-3 rounded-full hover:bg-primary focus:outline-none"
                    style={{ transition: 'background-color 0.2s ease' }}
                >
                    <i className="fas fa-ellipsis-v"></i>
                </button>
            </div>
            {/* Dropdown menu */}
            {activeVideoMenu === video.id && (
                <div className="absolute bottom-12 right-0 bg-white text-black shadow-lg rounded w-48">
                    <ul className="p-2">
                        <li className="hover:bg-gray-100 p-2 cursor-pointer flex items-center text-primary w-full">
                            <span onClick={(e) => handleMenuItemClick('move_to_trash', video.id, 'video', e)}>
                                <i className="fas fa-trash mr-2"></i>
                                {t('videos.move_to_trash')}
                            </span>
                        </li>
                        <li
                            className="hover:bg-gray-100 p-2 cursor-pointer flex items-center text-primary w-full"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering video card click
                                window.location.href = `/history/${video.id}`;
                            }}
                        >
                            <i className="fas fa-history mr-2"></i>
                            View History
                        </li>
                    </ul>
                </div>
            )}
        </div>

    );
};

export default VideoItem;
