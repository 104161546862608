import React from "react";

const FloatingActionButton = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="fixed bottom-5 right-5 bg-primaryAccent text-white shadow-lg hover:bg-primaryAccentHover transition-all duration-300 flex justify-center items-center"
            style={{ width: "50px", height: "50px", fontSize: "24px", borderRadius: "10px" }}
        >
            +
        </button>
    );
};

export default FloatingActionButton;
