import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../utils/ActionButton';
import Modal from './Modal';

const NoteModal = ({
    isOpen,
    onClose,
    noteTime,
    noteText,
    setNoteText,
    saveNote,
    deleteNote,
    editingBookmarkId,
    formatTime,
}) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h4 className="text-lg font-semibold mb-3">
                {editingBookmarkId ? t('video_content.edit_note') : t('video_content.add_note')}
            </h4>
            <p className="mb-2">
                {t('video_content.current_time')}:{' '}
                <strong>{formatTime(noteTime)}</strong>
            </p>
            <textarea
                className="w-full p-2 border border-gray-300 rounded mb-3"
                rows="4"
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                placeholder={t('video_content.note_placeholder')}
                required
            ></textarea>
            <div className="flex justify-end space-x-2">
                {editingBookmarkId && (
                    <ActionButton
                        label={t('video_content.delete_note')}
                        onClick={deleteNote}
                        customStyles="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded"
                    />
                )}
                <ActionButton
                    label={editingBookmarkId ? t('video_content.update_note') : t('video_content.save_note')}
                    onClick={saveNote}
                    customStyles="bg-primaryAccent hover:bg-primaryAccentHover text-white px-4 py-2 rounded"
                    disabled={!noteText.trim()}
                />
            </div>
        </Modal>
    );
};

export default NoteModal;
