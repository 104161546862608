import axiosInstance from "../axiosInstance";
import { BASE_URL } from "../config";

// Fetch folders by parent
export const fetchFoldersByParent = async (parentId = null) => {
    try {
        const url = parentId === null
            ? `${BASE_URL}/api/folders/parent/`
            : `${BASE_URL}/api/folders/parent/${parentId}/`;
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching folders:", error);
        return [];
    }
};

// Fetch folder details
export const getFolderDetails = async (folderId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/folders/${folderId}/`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching folder ${folderId}:`, error);
        throw error;
    }
};

// Create a new folder
export const createFolder = async (folderData, parentId) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/api/folders/create/`, {
            ...folderData,
            parent: parentId,
        });
        return response.data;
    } catch (error) {
        console.error("Error creating folder:", error);
        throw error;
    }
};
